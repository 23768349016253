// auth.js
export const LOGOUT = 'LOGOUT'
export const SAVE_TOKEN = 'SAVE_TOKEN'
export const FETCH_USER = 'FETCH_USER'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'

//attendance.js
export const SHOW_INFO_TAB = 'SHOW_INFO_TAB'
export const GET_YEAR = 'GET_YEAR'
export const GET_TOTAL_MONTHLY_DATA = 'GET_TOTAL_MONTHLY_DATA'
export const GET_UPDATE_ITEM_INFO = 'GET_UPDATE_ITEM_INFO'
export const GET_ALL_DATA = 'GET_ALL_DATA'
export const GET_DAILY_REPORT_DATA = 'GET_DAILY_REPORT_DATA'

//delivery.js
export const SHOW_ADD_DELIVERY_CONFIRM_MODAL = 'SHOW_ADD_DELIVERY_CONFIRM_MODAL'

//operation.js
export const SHOW_DRIVER_DETAIL_TAB = 'SHOW_DRIVER_DETAIL_TAB'
export const SAVE_DRIVER_INFO = 'SAVE_DRIVER_INFO'
export const GET_DRIVER_DATA_DETAIL = 'GET_DRIVER_DATA_DETAIL'
export const GET_DRIVER_LOCATION = 'GET_DRIVER_LOCATION'

//employee.js
export const SHOW_CONFIRM_MODAL = 'SHOW_CONFIRM_MODAL'

//home.js
export const GET_INTERVAL_TIME = 'GET_INTERVAL_TIME'