import * as types from "../mutation-types";

// state
export const state = {
  intervalTime: 2
}

// getters
export const getters = {
    intervalTime: (state) => state.intervalTime,
}

// mutations
export const mutations = {
  [types.GET_INTERVAL_TIME](state, payload) {
    state.intervalTime = payload
  },
}

// actions
export const actions = {
  getIntervalTime({
    commit
  }, payload) {
    commit(types.GET_INTERVAL_TIME, payload);
  },
}