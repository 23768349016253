export default {
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "properties": {
        "id": "1",
        "firstName": "沼田",
        "lastName": "敦",
        "status": "移動中",
        "startWork": "09:30",
        "violationNumber": 0,
        "lastUpdated": "2022/04/03 11:45",
      },
      "geometry": {
        "type": "Point",
        "coordinates": [136.879881, 35.170804]
      },
      "isShowDetail": false,
      "isShowDirection": false,
    },
    {
      "type": "Feature",
      "properties": {
        "id": "2",
        "firstName": "水谷",
        "lastName": "優子",
        "status": "休憩中",
        "startWork": "09:30",
        "violationNumber": 0,
        "lastUpdated": "2022/04/03 11:45",
      },
      "geometry": {
        "type": "Point",
        "coordinates": [136.880808, 35.168703]
      },
      "isShowDetail": false,
      "isShowDirection": false,
    },
    {
      "type": "Feature",
      "properties": {
        "id": "3",
        "firstName": "佐々木",
        "lastName": "茂",
        "status": "移動中",
        "startWork": "09:30",
        "violationNumber": 1,
        "lastUpdated": "2022/04/03 11:45",
      },
      "geometry": {
        "type": "Point",
        "coordinates": [136.883939, 35.171795]
      },
      "isShowDetail": false,
      "isShowDirection": false,
    },
    {
      "type": "Feature",
      "properties": {
        "id": "4",
        "firstName": "山本",
        "lastName": "浩紀",
        "status": "移動中",
        "startWork": "09:30",
        "violationNumber": 1,
        "lastUpdated": "2022/04/03 11:45",
      },
      "geometry": {
        "type": "Point",
        "coordinates": [136.886451, 35.167896]
      },
      "isShowDetail": false,
      "isShowDirection": false,
    },
    {
      "type": "Feature",
      "properties": {
        "id": "5",
        "firstName": "棚橋",
        "lastName": "棚橋",
        "status": "仮眠中",
        "startWork": "09:30",
        "violationNumber": 0,
        "lastUpdated": "2022/04/03 11:45",
      },
      "geometry": {
        "type": "Point",
        "coordinates": [136.884800, 35.167509]
      },
      "isShowDetail": false,
      "isShowDirection": false,
    },
    {
      "type": "Feature",
      "properties": {
        "id": "6",
        "firstName": "篠原",
        "lastName": "輝樹",
        "status": "待機中",
        "startWork": "09:30",
        "violationNumber": 0,
        "lastUpdated": "2022/04/03 11:45",
      },
      "geometry": {
        "type": "Point",
        "coordinates": [136.877393, 35.169969]
      },
      "isShowDetail": false,
      "isShowDirection": false,
    },
    {
      "type": "Feature",
      "properties": {
        "id": "7",
        "firstName": "東出",
        "lastName": "卓也",
        "status": "乗務前",
        "startWork": "09:30",
        "violationNumber": 0,
        "lastUpdated": "2022/04/03 11:45",
      },
      "geometry": {
        "type": "Point",
        "coordinates": [136.885681, 35.171687]
      },
      "isShowDetail": false,
      "isShowDirection": false,
    },
    {
      "type": "Feature",
      "properties": {
        "id": "8",
        "firstName": "水原",
        "lastName": "京子",
        "status": "更新なし",
        "startWork": "-",
        "violationNumber": "-",
        "lastUpdated": "",
      },
      "geometry": {
        "type": "Point",
        "coordinates": [136.879805, 35.172400]
      },
      "isShowDetail": false,
      "isShowDirection": false,
    },
  ],
  "histories": [
    {
      "id": "1",
      "data": [
        {
          "lastUpdate": "13:30", "status": "休憩終了", "address": "愛知県名古屋区東区葵1-10", "geometry": {
            "type": "Point",
            "coordinates": [136.879881, 35.170804]
          }
        },
        {
          "lastUpdate": "12:30", "status": "休憩開始", "address": "愛知県名古屋区東区葵1-10", "geometry": {
            "type": "Point",
            "coordinates": [136.879808, 35.171991]
          }
        },
        {
          "lastUpdate": "09:00", "status": "勤務開始", "address": "愛知県名古屋市中区4-2-22", "geometry": {
            "type": "Point",
            "coordinates": [136.879835, 35.172429]
          }
        },
        {
          "lastUpdate": "09:00", "status": "乗務前点呼完了", "address": "", "geometry": {
            "type": "Point",
            "coordinates": [136.879729, 35.172899]
          }
        },
      ]
    },
    {
      "id": "2",
      "data": [
        {
          "lastUpdate": "13:30", "status": "休憩終了-2", "address": "愛知県名古屋区東区葵1-10", "geometry": {
            "type": "Point",
            "coordinates": [136.875723, 35.176072]
          }
        },
        {
          "lastUpdate": "12:30", "status": "休憩開始-2", "address": "愛知県名古屋区東区葵1-10", "geometry": {
            "type": "Point",
            "coordinates": [136.875626, 35.175818]
          }
        },
        {
          "lastUpdate": "09:00", "status": "勤務開始-2", "address": "愛知県名古屋市中区4-2-22", "geometry": {
            "type": "Point",
            "coordinates": [136.875626, 35.175415]
          }
        },
        {
          "lastUpdate": "09:00", "status": "乗務前点呼完了-2", "address": "", "geometry": {
            "type": "Point",
            "coordinates": [136.875628, 35.175081]
          }
        },
      ]
    },
    {
      "id": "3",
      "data": [
        {
          "lastUpdate": "13:30", "status": "休憩終了-3", "address": "愛知県名古屋区東区葵1-10", "geometry": {
            "type": "Point",
            "coordinates": [136.879881, 35.170804]
          }
        },
        {
          "lastUpdate": "12:30", "status": "休憩開始-3", "address": "愛知県名古屋区東区葵1-10", "geometry": {
            "type": "Point",
            "coordinates": [136.879808, 35.171991]
          }
        },
        {
          "lastUpdate": "09:00", "status": "勤務開始-3", "address": "愛知県名古屋市中区4-2-22", "geometry": {
            "type": "Point",
            "coordinates": [136.879835, 35.172429]
          }
        },
        {
          "lastUpdate": "09:00", "status": "乗務前点呼完了-3", "address": "", "geometry": {
            "type": "Point",
            "coordinates": [136.879729, 35.172899]
          }
        },
      ]
    },
    {
      "id": "4",
      "data": [
        {
          "lastUpdate": "13:30", "status": "休憩終了", "address": "愛知県名古屋区東区葵1-10", "geometry": {
            "type": "Point",
            "coordinates": [136.879881, 35.170804]
          }
        },
        {
          "lastUpdate": "12:30", "status": "休憩開始", "address": "愛知県名古屋区東区葵1-10", "geometry": {
            "type": "Point",
            "coordinates": [136.879808, 35.171991]
          }
        },
        {
          "lastUpdate": "09:00", "status": "勤務開始", "address": "愛知県名古屋市中区4-2-22", "geometry": {
            "type": "Point",
            "coordinates": [136.879835, 35.172429]
          }
        },
        {
          "lastUpdate": "09:00", "status": "乗務前点呼完了", "address": "", "geometry": {
            "type": "Point",
            "coordinates": [136.879729, 35.172899]
          }
        },
      ]
    },
    {
      "id": "5",
      "data": [
        {
          "lastUpdate": "13:30", "status": "休憩終了", "address": "愛知県名古屋区東区葵1-10", "geometry": {
            "type": "Point",
            "coordinates": [136.879881, 35.170804]
          }
        },
        {
          "lastUpdate": "12:30", "status": "休憩開始", "address": "愛知県名古屋区東区葵1-10", "geometry": {
            "type": "Point",
            "coordinates": [136.879808, 35.171991]
          }
        },
        {
          "lastUpdate": "09:00", "status": "勤務開始", "address": "愛知県名古屋市中区4-2-22", "geometry": {
            "type": "Point",
            "coordinates": [136.879835, 35.172429]
          }
        },
        {
          "lastUpdate": "09:00", "status": "乗務前点呼完了", "address": "", "geometry": {
            "type": "Point",
            "coordinates": [136.879729, 35.172899]
          }
        },
      ]
    },
    {
      "id": "6",
      "data": [
        {
          "lastUpdate": "13:30", "status": "休憩終了", "address": "愛知県名古屋区東区葵1-10", "geometry": {
            "type": "Point",
            "coordinates": [136.879881, 35.170804]
          }
        },
        {
          "lastUpdate": "12:30", "status": "休憩開始", "address": "愛知県名古屋区東区葵1-10", "geometry": {
            "type": "Point",
            "coordinates": [136.879808, 35.171991]
          }
        },
        {
          "lastUpdate": "09:00", "status": "勤務開始", "address": "愛知県名古屋市中区4-2-22", "geometry": {
            "type": "Point",
            "coordinates": [136.879835, 35.172429]
          }
        },
        {
          "lastUpdate": "09:00", "status": "乗務前点呼完了", "address": "", "geometry": {
            "type": "Point",
            "coordinates": [136.879729, 35.172899]
          }
        },
      ]
    },
    {
      "id": "7",
      "data": [
        {
          "lastUpdate": "13:30", "status": "休憩終了", "address": "愛知県名古屋区東区葵1-10", "geometry": {
            "type": "Point",
            "coordinates": [136.879881, 35.170804]
          }
        },
        {
          "lastUpdate": "12:30", "status": "休憩開始", "address": "愛知県名古屋区東区葵1-10", "geometry": {
            "type": "Point",
            "coordinates": [136.879808, 35.171991]
          }
        },
        {
          "lastUpdate": "09:00", "status": "勤務開始", "address": "愛知県名古屋市中区4-2-22", "geometry": {
            "type": "Point",
            "coordinates": [136.879835, 35.172429]
          }
        },
        {
          "lastUpdate": "09:00", "status": "乗務前点呼完了", "address": "", "geometry": {
            "type": "Point",
            "coordinates": [136.879729, 35.172899]
          }
        },
      ]
    },
    {
      "id": "8",
      "data": []
    },
  ]
}
