import axios from "axios";
import Cookies from "js-cookie";
import * as types from "../mutation-types";
import store from "../index";

// state
export const state = {
  loading: false,
  token: Cookies.get("token"),
  employeeName: Cookies.get("employeeName"),
  employeeNumber: Cookies.get("employeeNumber"),
};

// getters
export const getters = {
  loading: (state) => state.loading,
  token: (state) => state.token,
  employeeName: (state) => state.employeeName,
  employeeNumber: (state) => state.employeeNumber,
  check: (state) => state.user !== null,
};

// mutations
export const mutations = {
  [types.SAVE_TOKEN](state, { token, employeeName, employeeNumber, remember }) {
    state.token = token;
    state.employeeName = employeeName;
    state.employeeNumber = employeeNumber;
    Cookies.set("token", token, { expires: remember ? 365 : null });
    Cookies.set("employeeNumber", employeeNumber, { expires: remember ? 365 : null });
    Cookies.set("employeeName", employeeName, { expires: remember ? 365 : null });
  },

  [types.FETCH_USER](state) {
    state.loading = true;
  },

  [types.FETCH_USER_SUCCESS](state, { user }) {
    state.user = user;
    state.loading = false;
  },

  [types.FETCH_USER_FAILURE](state) {
    state.token = null;
    Cookies.remove("token");
    state.loading = false;
  },

  [types.LOGOUT](state) {
    state.token = null;
    state.employeeName = null;
    state.employeeNumber = null;

    Cookies.remove("token");
    Cookies.remove("tenantId");
    Cookies.remove("employeeName");
    Cookies.remove("employeeNumber");
    Cookies.remove("userId");
    Cookies.remove("intervalItem");
    Cookies.remove("intervalTime");
    localStorage.removeItem('avatarImg')
  },
};

// actions
export const actions = {
  saveToken({ commit }, payload) {
    commit(types.SAVE_TOKEN, payload);
  },

  async logout({ commit }) {
    try {
      axios.defaults.headers.common = {
        Authorization: "Bearer " + Cookies.get("token").split("|")[1],
        "x-api-key": store.state.headers["x-api-key"]
      };
      await axios.post(store.state.serverUrl + "logout/v1");
    } catch (e) {
      console.log(e)
    }

    commit(types.LOGOUT);
  },
};
