import Vue from 'vue';
import Vuex from 'vuex';
import features from '../data/features'

Vue.use(Vuex)

// Load store modules dynamically.
const requireContext = require.context('./modules', false, /.*\.js$/)

const modules = requireContext.keys()
  .map(file =>
    [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)]
  )
  .reduce((modules, [name, module]) => {
    if (module.namespaced === undefined) {
      module.namespaced = true
    }

    return { ...modules, [name]: module }
  }, {})

export default new Vuex.Store({
  modules,
  state: {
    serverUrl: "https://dev-api.mobi-can.com/api/maintenance/",//"http://127.0.0.1:8000/api/maintenance/",//  
    blockChainUrl: "https://dev-bc-api.mobi-can.com/",
    headers: {
      "x-api-key": "base64:uWO1wX8RC8NbRQLsYM/ezjMbyMZe1/OnMVbJBShR1Co="
    },
    mapboxAccessToken: "pk.eyJ1IjoibGV0dWFuYXAiLCJhIjoiY2wwdW5hempmMGc5OTNja2JkYTllbnoxdyJ9.2FgxAT6m-HM4mpWpC_VSmg",
    googleApiKey: "AIzaSyCgEQabh9Fg0ouj0J2pv5q99g6wb4uKbfA",
    currentFeatures: features
  },
  mutations: {
    setCurrentFeatures: (state, features) => {
      state.currentFeatures = { ...state.fullFeatures, features: features }
    }
  }
});