<template>
  <div id="app">
    <header>
      <router-link to="/">
      </router-link>
    </header>
    <body>
      <router-view />
    </body>
  </div>
</template>
<script>
export default {};
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

html, body {
  font-family: 'Roboto', sans-serif;
}
#app{
  font-family: 'Roboto', sans-serif;
}
</style>