import * as types from "../mutation-types";

// state
export const state = {
    driverDetail: {
        item: null,
        showDetail: null
    },
    driverDataDetail: null,
    driverInfo: null,
    driverLocation: null,
}

// getters
export const getters = {
    driverDetail: (state) => state.driverDetail,
    driverDataDetail: (state) => state.driverDataDetail,
    driverInfo: (state) => state.driverInfo,
    driverLocation: (state) => state.driverLocation,
}

// mutations
export const mutations = {
    [types.SHOW_DRIVER_DETAIL_TAB](state, payload) {
        state.driverDetail = {
            ...state.driverDetail,
            ...payload
        };
    },

    [types.SAVE_DRIVER_INFO](state, payload) {
        state.driverInfo = {
            ...state.driverInfo,
            ...payload
        };
    },

    [types.GET_DRIVER_DATA_DETAIL](state, payload) {
        state.driverDataDetail = {
            ...state.driverDataDetail,
            ...payload
        };
    },

    [types.GET_DRIVER_LOCATION](state, payload) {
        state.driverLocation = {
            ...state.driverLocation,
            ...payload
        };
    },
}

// actions
export const actions = {
    showDriverDetail({
        commit
    }, payload) {
        commit(types.SHOW_DRIVER_DETAIL_TAB, payload);
    },

    saveDriverInfo({commit
    }, payload) {
        commit(types.SAVE_DRIVER_INFO, payload);
    },

    getDriverDetail({commit
    }, payload) {
        commit(types.GET_DRIVER_DATA_DETAIL, payload);
    },

    getLocation({commit
    }, payload) {
        commit(types.GET_DRIVER_LOCATION, payload);
    },
}