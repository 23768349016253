import * as types from "../mutation-types";

// state
export const state = {
  confirmModal: {
    show: false,
    title: "",
    message: "",
  },
}

// getters
export const getters = {
  confirmModal: (state) => state.confirmModal,
}

// mutations
export const mutations = {
  [types.SHOW_CONFIRM_MODAL](state, payload) {
    state.confirmModal = {
      ...state.confirmModal,
      ...payload
    };
  },
}

// actions
export const actions = {
  showConfirmModal({
    commit
  }, payload) {
    commit(types.SHOW_CONFIRM_MODAL, payload);
  },
}