import Vue from 'vue'
import Router from 'vue-router'
import Cookies from 'js-cookie';

Vue.use(Router)

function guardMyRoute(to, from, next) {
  var isAuthenticated = false;
  if (Cookies.get('token'))
    isAuthenticated = true;
  else
    isAuthenticated = false;
  if (isAuthenticated) {
    next(); // allow to enter route
  }
  else {
    next('/login'); // go to '/login';
  }
}

// return to home page when go to login after login
function guardToLogin(to, from, next) {
  var isAuthenticated = false;
  if (Cookies.get('token'))
    isAuthenticated = true;
  else
    isAuthenticated = false;

  if (isAuthenticated && to.name === 'Login') {
    next('/');
  } else {
    next() // go to '/login';
  }
}


export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'Login',
      beforeEnter: guardToLogin,
      component: () =>
        import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    },
    {
      path: '/driver-list',
      name: 'DriverTrackingList',
      beforeEnter: guardMyRoute,
      component: () =>
        import(/* webpackChunkName: "driverTrackingList" */ "../views/DriverTrackingList.vue")
    },
    {
      path: '/driver-list/:id?',
      name: 'DriverTrackingDetail',
      beforeEnter: guardMyRoute,
      component: () =>
        import(/* webpackChunkName: "driverTrackingDetail" */ "../views/DriverTrackingDetail.vue")
    },
    {
      path: '/',
      name: 'Home',
      beforeEnter: guardMyRoute,
      component: () =>
        import(/* webpackChunkName: "home" */ "../views/Home.vue"),
      children: [
        {
          path: '',
          name: 'LocationManage',
          component: () =>
            import(/* webpackChunkName: "locationManage" */ "../views/LocationManage.vue"),
        },
        {
          path: '/attend-manage',
          name: 'AttendanceManage',
          component: () =>
            import(/* webpackChunkName: "attendanceManage" */ "../views/AttendanceManage.vue"),
        },
        {
          path: '/attend-manage/detail:id?',
          name: 'AttendanceManageDetail',
          component: () =>
            import(/* webpackChunkName: "attendanceManageDetail" */ "../views/AttendanceManageDetail.vue"),
        },
        {
          path: '/employee-data',
          name: 'EmployeeDataManage',
          component: () =>
            import(/* webpackChunkName: "employeeDataManage" */ "../views/EmployeeDataManage.vue"),
        },
        {
          path: '/delivery-plan',
          name: 'DeliveryPlanManage',
          component: () =>
            import(/* webpackChunkName: "deliveryPlanManage" */ "../views/DeliveryPlanManage.vue"),
        }
      ]
    },
    {
      path: '/password-setting',
      name: 'PasswordSetting',
      beforeEnter: guardMyRoute,
      component: () =>
        import(/* webpackChunkName: "passwordSetting" */ "../views/Auth/PasswordSetting.vue"),
    },
    {
      path: '/password-reset',
      name: 'ResetPassword',
      component: () =>
        import(/* webpackChunkName: "resetPassword" */ "../views/Auth/ResetPassword.vue"),
    },
  ]
})
