import * as types from "../mutation-types";
//import axios from "axios";

// state
export const state = {
    infoTab: null,
    employeeData: {
        user_id: null,
        year: null,
    },

    totalData: null,
    updateItem: null,
    allData: null,
    dailyReportData: null,
}

// getters
export const getters = {
    infoTab: (state) => state.infoTab,
    employeeData: (state) => state.employeeData,
    totalData: (state) => state.totalData,
    updateItem: (state) => state.updateItem,
    allData: (state) => state.allData,
    dailyReportData: (state) => state.dailyReportData,
}

// mutations
export const mutations = {
    [types.SHOW_INFO_TAB](state, payload) {
        state.infoTab = payload
    },

    [types.GET_YEAR](state, payload) {
        state.employeeData = {
            ...state.employeeData,
            ...payload
        }
    },

    [types.GET_TOTAL_MONTHLY_DATA](state, payload) {
        state.totalData = payload
    },

    [types.GET_UPDATE_ITEM_INFO](state, payload) {
        state.updateItem = payload
    },

    [types.GET_ALL_DATA](state, payload) {
        state.allData = payload
    },

    [types.GET_DAILY_REPORT_DATA](state, payload) {
        state.dailyReportData = payload
    },
}

// actions
export const actions = {
    showInfoTab({
        commit
    }, payload) {
        commit(types.SHOW_INFO_TAB, payload);
    },

    getYear({
        commit
    }, payload) {
        commit(types.GET_YEAR, payload);
    },

    getDataMonthly({
        commit
    }, payload) {
        commit(types.GET_TOTAL_MONTHLY_DATA, payload);
    },

    getUpdateItemInfo({
        commit
    }, payload) {
        commit(types.GET_UPDATE_ITEM_INFO, payload);
    },

    getAllData({
        commit
    }, payload) {
        commit(types.GET_ALL_DATA, payload);
    },

    getDailyReportData({
        commit
    }, payload) {
        commit(types.GET_DAILY_REPORT_DATA, payload);
    },
}